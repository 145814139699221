@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#faf7f4] min-h-screen dark:bg-[#1e1e1e] transition-all duration-300;
}

.bg-container {
  @apply mx-auto max-w-[90%] lg:max-w-[85%];
}

h1,
h2,
h3,
h4,
h5,
h6, p, li {
  @apply  dark:text-[#efefef];
}